import { cn } from '@rouvydev/web-components/utils'
import { Cols } from '~/components/cols.tsx'
import { Gradient3 } from '~/components/gradients.tsx'

export function ArticleHeader({
  title,
  showBg = true,
  children,
}: {
  title: string
  showBg?: boolean
  children?: React.ReactNode
}) {
  return (
    <div className="relative overflow-hidden">
      <Gradient3
        className={cn(
          showBg ? 'opacity-100' : 'opacity-0',
          'transition-opacity duration-500',
        )}
      />
      <div
        className={cn(
          'absolute -top-[669px] left-1/2 h-[1835px] w-[3200px] -translate-x-1/2 rotate-[159deg] bg-pattern-1 transition-opacity duration-500',
          showBg ? 'opacity-100' : 'opacity-0',
        )}
      />
      <div className="container relative">
        <div className="py-24">
          <Cols size="8">
            <h1 className="text-center font-display text-display-84 uppercase italic leading-tight text-text-default">
              {title}
            </h1>
            {children}
          </Cols>
        </div>
      </div>
    </div>
  )
}
