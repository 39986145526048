import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'
import { GeneralErrorBoundary } from '~/components/error-boundary.tsx'
import { Layout } from '~/components/layout.tsx'

export default function Blog() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation(['blog'])
  return (
    <GeneralErrorBoundary
      unexpectedErrorHandler={() => (
        <p className="text-body-20 font-semibold text-text-muted">
          {t('blog_not_loaded')}
        </p>
      )}
    />
  )
}
