import { cn } from '@rouvydev/web-components/utils'
import { type ReactNode } from 'react'

export function Article({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return (
    <div
      className={cn(
        'prose-xl prose-h1:text-display-48 prose-h2:text-display-40 prose-h2:text-text-default prose-h3:text-[2.25rem] prose-h3:text-text-default prose-h4:text-body-32 prose-h4:text-text-default prose-h5:text-display-28 prose-h5:text-text-default prose-h6:text-body-24 prose-h6:text-text-default prose-p:text-body-16 prose-p:text-text-muted prose-a:text-link-rest visited:prose-a:text-text-secondary hover:prose-a:text-link-hover prose-strong:font-bold prose-strong:text-text-muted prose-li:list-disc prose-li:text-body-14 prose-li:text-text-default prose-th:text-body-16 prose-th:text-text-default prose-td:text-body-14 prose-td:text-text-muted prose-hr:h-[1px] prose-hr:w-full prose-hr:shrink-0 prose-hr:border-border-secondary-subtle ',
        className,
      )}
    >
      {children}
    </div>
  )
}
